/* Design System :: LATAM COLORS */
:root {
	---color-black-full: #000;
	--color-black: #303030;
	--color-white: #fff;

	// gray
	--color-gray-dark: #5c5c5c;
	--color-gray-subDark: #858585;
	--color-gray-light: #e6e6e6;
	--color-gray-sublight: #e5e5e5;
	--color-gray-lightest: #ececec;
	--color-gray-soft: #f5f5f5;
	--color-gray-disabled: #cccccc;
	--color-gray: #707070;

	// primary colors
	--color-blue-dark: #10004f;
	--color-blue: #1b0088;
	--color-blue-light: #4650df;
	--color-blue-soft: #edecf8;
	--color-blue-lightest: #edecf8;
	--color-pink: #e8114b;
	--color-green-water: #8eddd9;
	--color-purple-light: #e7e4fd;
	--color-purple: #7263b0;
	--color-purple-dark: #300330;

	// info
	--color-green-success: #3a8340;
	--color-green: #00aca3;
	--color-red-failure: #a63449;
}
