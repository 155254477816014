/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
	box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
	margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
	height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
	font: inherit;
}

a {
	all: unset;
	cursor: pointer;
}

ul,
li {
	padding: 0;
	margin: 0;
}

button {
	background-color: transparent;
	border: none;
	margin: 0;
	padding: 0;
	text-align: inherit;
	font: inherit;
	border-radius: 0;
	appearance: none;
	cursor: pointer;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root {
	isolation: isolate;
}
