/* You can add global styles to this file, and also import other style files */

@import 'reset';
@import 'utility';
@import 'mixin';

@font-face {
	font-family: 'BB Gastronomia Textos';
	src: url('assets/fonts/BancoDoBrasilTextos-Bold-web.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'BB Gastronomia Textos';
	src: url('assets/fonts/BancoDoBrasilTextos-XBoldIt-web.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'BB Gastronomia Textos';
	src: url('assets/fonts/BancoDoBrasilTextos-XBold-web.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'BB Gastronomia Textos';
	src: url('assets/fonts/BancoDoBrasilTextos-MediumIt-web.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'BB Gastronomia Textos';
	src: url('assets/fonts/BancoDoBrasilTextos-Italic-web.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'BB Gastronomia Textos';
	src: url('assets/fonts/BancoDoBrasilTextos-LightIt-web.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'BB Gastronomia Textos';
	src: url('assets/fonts/BancoDoBrasilTextos-BoldIt-web.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'BB Gastronomia Textos';
	src: url('assets/fonts/BancoDoBrasilTextos-Light-web.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'BB Gastronomia Textos';
	src: url('assets/fonts/BancoDoBrasilTextos-Medium-web.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'BB Gastronomia Textos';
	src: url('assets/fonts/BancoDoBrasilTextos-Regular-web.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'BB Gastronomia Titulos';
	src: url('assets/fonts/BancoDoBrasilTitulos-LightIt-web.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'BB Gastronomia Titulos';
	src: url('assets/fonts/BancoDoBrasilTitulos-Medium-web.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'BB Gastronomia Titulos';
	src: url('assets/fonts/BancoDoBrasilTitulos-BoldIt-web.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'BB Gastronomia Titulos';
	src: url('assets/fonts/BancoDoBrasilTitulos-Regular-web.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'BB Gastronomia Titulos';
	src: url('assets/fonts/BancoDoBrasilTitulos-MediumIt-web.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'BB Gastronomia Titulos';
	src: url('assets/fonts/BancoDoBrasilTitulos-XBold-web.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'BB Gastronomia Titulos';
	src: url('assets/fonts/BancoDoBrasilTitulos-XBoldIt-web.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'BB Gastronomia Titulos';
	src: url('assets/fonts/BancoDoBrasilTitulos-Bold-web.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'BB Gastronomia Titulos';
	src: url('assets/fonts/BancoDoBrasilTitulos-Italic-web.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'BB Gastronomia Titulos';
	src: url('assets/fonts/BancoDoBrasilTitulos-Light-web.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

:root {
	font-family: 'BB Gastronomia Textos', 'Open Sans', sans-serif;
	--bgImg-banner: url('https://tagmepub.blob.core.windows.net/pubimg/Channels/BB Gastronomia/banner_livelo.png');
	--bgImg-works: url('https://tagmepub.blob.core.windows.net/pubimg/Channels/BB Gastronomia/banner_livelo.png');

	//BB
	--content-primary: #212227;
	--content-secondary: #3354fd;
	--content-secondary-200: #4969e633;
	--content-secondary-300: #4969e6;
	--content-tertiary: #fefb5f;
	--content-state-disabled: #8b8c93;
	--content-deselected: #a1a1a1;

	--content-divider: #00000014;
	--content-info: #1d5ebf;
	--content-on-color: #ffffff;

	--content-positive: #19803b;
	--content-dark: #0c302c;
	--content-warning: #bf4b1d;

	--container-desktop: 1086px;
	--height-desktop: min(70dvh, 750px);

	//BB
	--background-primary: #ffffff;
	--background-secondary-100: #eef2ff;
	--background-secondary: #4969e6;
	--background-tertiary: #fefb5f;
	--background-disabled: #efedea;
	--background-warning: #ffdfdf;

	--primitives-black: #000000;
	--primitives-black-300: #1c1b1f;
	--primitive-black-500: #222222;
	--primitives-black-200: #222;
	--primitives-brack-300: #1c1b1f;
	--primitives-grey-100: #f0f0f0;
	--primitives-grey-200: #d8d8d8;
	--primitives-grey-225: #ebebeb;
	--primitives-grey-250: #c0c0c0;
	--primitives-grey-300: #e2e2e2;
	--primitive-grey-400: #f7f5f5;
	--primitives-grey-500: #585b65;
	--primitives-grey-600: #767676;
	--primitives-white: #ffffff;
	--primitive-pink: #df0979;

	--primitives-red-200: #ffdfdf;
	--primitives-red-500: #ee0808;
	--background-negative: #ee0808;
}

.bbTitle {
	margin: 32px 0 16px 0;
	color: var(--content-primary);
	font-size: 24px;
	font-weight: 500;
	line-height: 28px;

	@include laptop-up {
		margin: 0;
	}
}

.boxi7kr3 {
	display: flex;
	flex-direction: column;
	gap: 9px;
	padding: 12px 0;
	width: 100%;
	height: 68px;

	&__title {
		color: var(--content-secondary-300);
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
	}

	&__input {
		border: none;
		color: var(--content-primary);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
	}

	&__input::placeholder {
		font-size: 16px;
	}

	&__input:focus {
		border-top: none;
		border-right: none;
		border-left: none;
		outline: none;
	}
}

.boxBlackDivider {
	width: 100%;
	position: fixed;
	bottom: 0;
}

.blackDivider {
	border-radius: 100px;
	width: 134px;
	height: 5px;
	margin: 0 auto 8px;
	background-color: var(--primitives-black);
}

// .temp-mobile-w {
// 	width: 373px !important;
// 	margin: 0 auto;
// 	right: 0;
// }

// .temp-mobile-h {
// 	height: 264px !important;
// }
