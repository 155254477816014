@mixin mobile-up {
	@media (min-width: 1px) {
		@content;
	}
}

@mixin tablet-up {
	@media (min-width: 577px) {
		@content;
	}
}

@mixin laptop-up {
	@media (min-width: 993px) {
		@content;
	}
}

@mixin desktop-up {
	@media (min-width: 1201px) {
		@content;
	}
}
